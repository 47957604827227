@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-starter {
    --color-sidebar: #333;
    --color-sidebar-light: #999;

    --color-primary: #003e78;
    --color-primary-light: #00529f;
    --color-primary-dark: #00376c;
    --color-secondary: #72b4ff;
    --color-secondary-light: #80bbff;
    --color-gold: #ffcb0b;

    --color-primary-50: #EFF6FF;
    --color-primary-100: #DBEAFE;
    --color-primary-200: #BFDBFE;
    --color-primary-300: #93C5FD;
    --color-primary-400: #60A5FA;
    --color-primary-500: #3B82F6;
    --color-primary-600: #2563EB;
    --color-primary-700: #1D4ED8;
    --color-primary-800: #1E40AF;
    --color-primary-900: #1E3A8A;

    --ring-primary: rgb(0, 62, 120) 0px 0px 0px 0px, rgb(0, 62, 120) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    --color-secondary-50: #fafafa;
    --color-secondary-100: #f4f4f5;
    --color-secondary-200: #e4e4e7;
    --color-secondary-300: #d4d4d8;
    --color-secondary-400: #a1a1aa;
    --color-secondary-500: #71717a;
    --color-secondary-600: #52525b;
    --color-secondary-700: #3f3f46;
    --color-secondary-800: #27272a;
    --color-secondary-900: #18181b;

    /*--color-secondary-50: #f5f5f5;*/
    /*--color-secondary-100: #ebebeb;*/
    /*--color-secondary-200: #cccccc;*/
    /*--color-secondary-300: #adadad;*/
    /*--color-secondary-400: #707070;*/
    /*--color-secondary-500: #333333;*/
    /*--color-secondary-600: #2e2e2e;*/
    /*--color-secondary-700: #262626;*/
    /*--color-secondary-800: #1f1f1f;*/
    /*--color-secondary-900: #191919;*/

    --color-inverse: #fff;

    --font-display: 'Roboto', sans-serif;
    --font-body: 'Roboto', sans-serif;

    --font-weight-normal: 400;
    --font-weight-display: 600;
    --font-weight-btn: 600;

    --rounded-btn: .3em;
}

@layer components {
    .min-w-icon {
        min-width: 1.25rem;
    }

    .form-control {
        @apply block w-full min-h-[2.25rem] px-2 border shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-secondary-300 rounded-btn bg-inverse text-secondary-900 placeholder-secondary-600 placeholder-secondary-300
    }

    .form-error {
        @apply ring-red-300 ring-4
    }

    .btn {
        @apply relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-semibold
    }

    .btn-link {
        @apply border-transparent hover:opacity-70 text-primary-700 font-btn
    }

    .btn-primary {
        @apply rounded-btn text-white bg-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 shadow-sm
    }

    .btn-secondary {
        @apply rounded-btn text-white bg-secondary hover:bg-secondary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 shadow-sm
    }

    .btn-outline {
        @apply border-secondary-300 rounded-btn shadow-sm text-secondary-700 bg-transparent hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
    }

    .btn-disabled {
        @apply rounded-btn bg-primary-600 hover:bg-primary-600 text-inverse border-primary-600 opacity-30 cursor-not-allowed
    }

    .text-area {
        @apply w-full form-control
    }

    .participants-3,
    .participants-4 {
        @apply col-span-4
    }
}

html {
    font-family: 'Barlow', sans-serif;
}


a {
    color: var(--color-primary-600);
}

/* Helpers */
.inset-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.-z-1 {
    z-index: -1;
}

.flex-full {
    flex: 1 1 100%;
}

.transition-width {
    transition-property: width;
}

.w-icon {
    min-width: 1.5rem;
}

.min-w-32 {
    min-width: 8rem;
}

.page-content-height {
    min-height: calc(100vh - 109px);
}

/* Override */
.form-react-select input:focus {
    box-shadow: none;
}

.top-55 {
    top: 55px;
}

.min-h-90vh {
    min-height: 90vh;
}

.w-fit-content {
    width: fit-content;
}

.webkit-text-right {
    text-align: -webkit-right;
}

.h-50vh {
    height: 50vh;
}

.dropdown-40 [class$="-menu"] > * {
    height: 10em;
    overflow-y: scroll;
}

.dropdown-48 [class$="-menu"] > * {
    height: 12em;
    overflow-y: scroll;
}


.dropdown-60 [class$="-menu"] > * {
    height: 15em;
    overflow-y: scroll;
}

.dropdown-80 [class$="-menu"] > * {
    height: 20em;
    overflow-y: scroll;
}


.border-spacing-0 {
    border-spacing: 0;
}


/* VOICE ANIMATION */
/*.voice-container {*/
/*    width: 36px;*/
/*    height: 36px;*/
/*    flex: 0 0 auto;*/
/*    display: block;*/
/*    border-radius: 50%;*/
/*    border: 1px solid #fff;*/
/*    background: #00a88b !important;*/
/*    margin-right: 1rem;*/
/*    position: absolute;*/
/*    bottom: 50px;*/
/*    left: 10px;*/
/*}*/

.voice-animation {
    width: 4px;
    height: 12px;
    background: #ffffff;
    animation: listening 1s ease-in-out infinite;
    animation-delay: 0.4s;

    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.voice-animation:after,
.voice-animation:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 8px;
    background: #ffffff;
    animation: listening 1s ease-in-out infinite;
}

.voice-animation:before {
    right: 9px;
    animation-delay: 0.2s;
}

.voice-animation:after {
    left: 9px;
    animation-delay: 0.6s;
}

@keyframes listening {
    0%, 100% {
        box-shadow: 0 0 0 #ffffff, 0 0 0 #ffffff;
    }
    50% {
        box-shadow: 0 -4px 0 #ffffff, 0 4px 0 #ffffff;
    }
}

/* Video */
.w-chat-room {
    max-width: 480px;
}

/*.preview-video {*/
/*    display: block;*/
/*    outline: none;*/
/*    box-shadow: none;*/
/*    position: relative;*/
/*    margin: 0 auto;*/
/*    background: #0a0a0a;*/
/*    max-width: 480px;*/
/*}*/

.video-wrap {
    display: inline-block;
    width: 95%;
    position: relative;
    margin: 1%;
    vertical-align: top;
}

/* VIDEO */
/*video {*/
/*    display: block;*/
/*    outline: none;*/
/*    -webkit-box-shadow: none;*/
/*    -moz-box-shadow: none;*/
/*    box-shadow: none;*/
/*    background: #d4d4d8;*/
/*    width: 100%;*/
/*    height: 37vh;*/
/*}*/

.video-ratio {
    /*padding-top: 75%;*/
    padding-top: 200px;
}

.video-ratio-3\:4 {
    padding-top: 75%;
}

@media (min-width: 648px) {
    .video-ratio {
        padding-top: 21vw;
    }
}

@media (min-width: 1600px) {
    .video-ratio {
        padding-top: 18vw;
    }
}

.inside-btn {
    position: absolute;
    bottom: 27px !important;
    left: 50%;
    transform: translate(-50%, -20px);
    display: flex;
    cursor: alias !important;
}

video::-webkit-media-controls {
    display: none !important;
}

.conf-presenter-btn-wrapper {
    position: absolute;
    left: calc(35vw - 32px);
    bottom: -50px;
}

.conf-icon-btn {
    display: inline-block;
    cursor: pointer;
    background: white;
    border-radius: 50%;
    padding: 2px;
    margin: 0 6px;
}

.conf-icon-btn img {
    width: 40px;
    margin: 3px;
}

/* MY CONTROLS */
.conference-my-controls {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    background-color: #00a88b;
    text-align: center;
    padding: 5px;
}

#fullscreenbtn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

#fullscreenbtn img {
    width: 30px;
}

/* Debug */
.webrtc-debug {
    position: fixed;
    right: 0;
    bottom: 0;
    background: #fff;
    width: 200px;
    border: 1px solid gray;
    padding: 5px 5px;
    z-index: 5;
}

.h-100 {
    height: 100vh;
}

.height-video {
    min-height: calc(100vh - 120px);
}

.videos-main-wrap {
    display: inline-block;
    width: calc(100% - 320px);
    height: calc(100vh - 130px);;
    vertical-align: top;
}

.videos-main-wrap.full-width-video {
    width: 100%;
}

.videos-main-wrap .video-wrap-presenter {
    width: 80%;
    position: relative;
    border: 5px solid grey;
    background-color: #d4d4d8;
}

.videos-main-wrap .video-wrap-presenter .video-name-user, .videos-main-wrap .video-wrap-presenter .video-name-presenter,
.videos-main-wrap .video-wrap-user .video-name-user, .videos-main-wrap .video-wrap-user .video-name-presenter {
    color: #000000;
}

.videos-main-wrap .video-wrap-presenter .video-border-inner-fill {
    border: 4px solid;
    border-width: 4px;

    -moz-border-image: -moz-linear-gradient(to bottom, #e2e2e2 10%, #b3b3b3 15%, #000000 30%, #ffffff 65%, #b3b3b3 75%, #000000 100%);
    -webkit-border-image: -webkit-linear-gradient(to bottom, #e2e2e2 10%, #b3b3b3 15%, #000000 30%, #ffffff 65%, #b3b3b3 75%, #000000 100%);
    border-image: linear-gradient(to bottom, #e2e2e2 10%, #b3b3b3 15%, #000000 30%, #ffffff 65%, #b3b3b3 75%, #000000 100%);
    -webkit-border-image-slice: 1;
    border-image-slice: 1;
}

.videos-main-wrap .video-wrap-presenter .video-border-inner {
    border: 5px solid grey;
}

.videos-main-wrap .video-wrap-presenter video {
    width: 100%;
    height: 60vh;
}

.videos-main-wrap {
    position: relative;
}

.videos-main-wrap .video-wrap-user {
    position: absolute;
    width: 17%;
    right: 0;
    top: 45px;
    height: 27vh;
    border: 5px solid grey;
    background-color: #d4d4d8;
}

.videos-main-wrap .video-wrap-user .video-border-inner-fill {
    border: 4px solid;
    border-width: 4px;

    -moz-border-image: -moz-linear-gradient(to bottom, #e2e2e2 10%, #b3b3b3 15%, #000000 30%, #ffffff 65%, #b3b3b3 75%, #000000 100%);
    -webkit-border-image: -webkit-linear-gradient(to bottom, #e2e2e2 10%, #b3b3b3 15%, #000000 30%, #ffffff 65%, #b3b3b3 75%, #000000 100%);
    border-image: linear-gradient(to bottom, #e2e2e2 10%, #b3b3b3 15%, #000000 30%, #ffffff 65%, #b3b3b3 75%, #000000 100%);
    -webkit-border-image-slice: 1;
    border-image-slice: 1;
}

.videos-main-wrap .video-wrap-user .video-border-inner {
    border: 5px solid grey;
}

.videos-main-wrap .video-wrap-user video {
    height: 20vh;
}

.videos-main-wrap #presenter .video-name-container {
    width: 17%;
}

.chat-container {
    display: inline-block;
    vertical-align: top;
    max-height: calc(100vh - 130px);
    overflow-y: auto;
    overflow-x: hidden;
}

.chat-wrapper {
    display: inline-block;
    word-break: break-word;
    overflow-y: auto;
    height: 50vh;
    border: 2px solid #00a88b;
    padding: 5px;
}

.chat-username-style {
    font-weight: bold;
    font-style: italic;
}

.chat-send-message {
    display: inline-block;
}

.chat-send-message textarea {
    width: 100%;
    resize: none;
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
    border: 2px solid #00a88b;
}

.video-input-range {
    -webkit-appearance: none;
    background: transparent;
}

.video-input-range::-webkit-slider-runnable-track {
    height: 0.5rem;
    -webkit-appearance: none;
    background: transparent;
    border-radius: 25px;
}

.video-input-range::-webkit-slider-thumb {
    border: 0;
    height: 0;
    width: 0;
    border-radius: 100%;
    -webkit-appearance: none;
    background: var(--color-primary-600);
    margin-top: -.5rem;
    margin-left: -.2rem;
}

.video-input-range:hover::-webkit-slider-thumb {
    height: 1rem;
    width: 1rem;
}

.video-input-range:focus::-webkit-slider-runnable-track {
    background: transparent;
}

.video-input-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    background: transparent;
    border-radius: 0.5rem;
}

.video-input-range::-moz-range-thumb {
    border: 0;
    height: 0;
    width: 0;
    border-radius: 100%;
    background: var(--color-primary-600);
    margin-left: -.5rem;
}

.video-input-range:hover::-moz-range-thumb {
    height: 1rem;
    width: 1rem;
}

.video-input-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
}

.video-input-range::-ms-fill-lower {
    background: var(--color-primary-200);

    border-radius: 50px;
}

.video-input-range::-ms-fill-upper {
    background: var(--color-primary-200);

    border-radius: 50px;
}

.video-input-range::-ms-thumb {
    border: 0px solid #000000;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 100%;
    background: var(--color-primary-600);
}

.video-input-range:focus::-ms-fill-lower {
    background: var(--color-primary-200);
}

.video-input-range:focus::-ms-fill-upper {
    background: var(--color-primary-200);
}

.video-progress {
    -webkit-appearance: none;
    appearance: none;
    height: 0.5rem;
    border-radius: 1.5rem;
}

.video-progress::-webkit-progress-bar {
    background: var(--color-secondary-200);
    border-radius: 1.5rem;
}

.video-progress::-webkit-progress-value {
    background: var(--color-primary-400);
    border-radius: 1.5rem;
}


.video-progress::-moz-progress-bar {
    background: var(--color-primary-400);
    border-radius: 1.5rem;
}

/*.participant-wrapper {*/
/*    position: relative;*/
/*}*/

.participant-wrapper .video-poster-logo {
    position: absolute;
    width: 320px;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.recordings-table {
    max-height: 55vh;
    overflow-y: auto;
}

.table-td-textarea {
    max-width: 25vw !important;
    overflow-x: auto;
    cursor: help;
}

.table-mobile td:last-child {
    padding-top: .3rem;
    padding-bottom: .3rem;
}

.table-mobile td:first-child {
    border-top-right-radius: 0.5rem;
}

.table-mobile th:first-child {
    border-top-left-radius: 0.5rem;
}

.volume-button:focus + div {
    width: 5rem;
    opacity: 1;
}

.sound-input-range::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 0.25rem;
    background: var(--color-secondary-200);
    border-radius: 0.5rem;
}

.sound-input-range::-webkit-slider-thumb {
    border: 0;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    -webkit-appearance: none;
    background: var(--color-secondary-200);
    margin-top: -.35rem;
    margin-left: -.2rem;
}

.sound-input-range::-moz-range-track {
    width: 100%;
    height: 0.25rem;
    background: var(--color-secondary-200);
    border-radius: 0.5rem;
}

.sound-input-range::-moz-range-thumb {
    border: 0;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    background: var(--color-primary-600);
    margin-left: -.5rem;
}

.promo-page {
    position: relative;
    min-height: -webkit-calc(100vh - 320px);
    min-height: expression(100vh - 320px);
    min-height: -moz-calc(100vh - 320px);
    min-height: -o-calc(100vh - 320px);
    min-height: calc(100vh - 320px);
}

.promo-page h4 {
    color: #ffffff;
    max-width: 1400px;
    padding-top: 10vh;
}


@media (min-width: 1024px) {
    .promo-page h4 {
        width: 80%;
        padding-top: 15vh;
        margin: 0 auto;
    }
}

/*@media (min-width: 1536px) {*/
/*    .promo-page h4 {*/
/*        margin: 15vh auto 0;*/
/*    }*/
/*}*/

.promo-page ul {
    margin-top: 30px;
}

.promo-page-list {
    max-width: 1800px;
}

.promo-page-list > div {
    color: #ffffff;
}

.promo-footer {
    border-top: 10px solid black;
}

.background-image-promo {
    background-image: url("./images/backgroundImage.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.custom-min-h-page {
    min-height: 80vh !important;
}

.custom-min-h-page-2 {
    min-height: 86vh !important;
}

.custom-min-h-tab {
    /*min-height: 77vh;*/
    min-height: 64vh
}

@media only screen and (max-width: 1300px) {
    .custom-min-h-tab {
        min-height: 67vh;
    }
}

.social-icon-w-30 {
    width: 30px !important;
    height: 30px !important;
}

.slick-dots li.slick-active button::before,
.slick-dots li button::before {
    color: #fff;
}

@media only screen and (max-width: 640px) {
    .promo-text-h-mobile {
        font-size: 1.3rem !important;
    }
}

@media (max-width: 639px) {
    .calendar-row td:nth-child(-n+3) .right-1 {
        right: auto;
        left: 0.25rem;
    }
}

.passwordStrength {
    display: block;
    width: 100%;
    height: 4px;
    position: relative;
    left: -2px;
}

.passwordStrengthTxt {
    position: absolute;
    right: 14px;
    top: 12px;
}

.border-bottom-navigation {
    /*border-bottom: 1px solid #fff;*/
    border-bottom: 1px solid rgba(255, 255, 255, 0.125);
}

/* Promo page */
.max-w-8xl {
    max-width: 90rem;
}

.primary-color {
    color: #5DD3D8;
}

.share-on-wrap {
    height: 32px;
    bottom: 1px;
    position: relative;
}

.social-roles-wrap {
    padding-bottom: 2px;
    display: inline-block;
    position: relative;
    top: -5px;
}

.tox .tox-editor-header {
    z-index: 0 !important;
}

.page-table-height {
    min-height: 70vh;
}

.svg-sm svg{
    width: 20px;
    margin-right: .5rem;
}

.video-wrap-parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
}

.video-grid-height {
    height: calc(100vh - 146px);
    display: flex;
    flex-flow: row wrap;
    place-content: center;
}

.react-add-to-calendar {
    display: inline-block;
    border: 1px solid #00a88b;
    border-radius: 12px;
    padding: 8px;
    margin-right: 12px;
    font-size: 14px;
    position: relative;
}
.react-add-to-calendar:hover {
    cursor: pointer;
}
.react-add-to-calendar__dropdown {
    position: absolute;
    background: #fff;
    left: 0px;
    top: 40px;
    padding: 12px;
}
.user-cover {
    top: 50%;
    left: 50%;
    margin-left: -4rem;
    margin-top: -4rem;
}

/* Slick slider customization */
.slick-contain img {
    width: 100%;
    height: 100%;
}

.slick-contain div {
    height: 100%!important;
}

.slick-dots {
    transform: translateY(-150%);
    z-index: 10;
    position: relative;
    bottom: 0;
}

.slick-dots li button::before {
    font-size: 12px;
    color: #fff;
    opacity: .75;
}

.slick-dots li.slick-active button::before {
    color: var(--color-primary-600);
    opacity: 1;
}

@media (min-width: 1024px) {
    .slick-contain img {
        max-width: none;
        top: 0;
    }
}

/* Slick slider customization end*/
.top-fix-calendar-info {
    top: -50px;
}
.top-65 {
    top: 65px;
}
.top-130 {
    top: 130px;
}
.right-360 {
    right: -360px;
}

.h-350 {
    height: 350px;
}

.block-watcher-sidebar {
    width: 350px;
    position: fixed;
    top: 130px;
    right: -300px;
    transition: .6s ease;
}

.block-watcher-sidebar:hover {
    right: 0;
}
